import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import moment from 'moment-timezone';
import DrawAnnotations from './DrawAnotations';
import ModalErrors from './ModalErrors';
import html2canvas from 'html2canvas';

class CorrecaoDissertativa extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            sala: {},
            resposta: {},
            domain: '',
            height: 0,
            width: 100,
            c1: 0,
            c2: 0,

            // c3: 4,
            c3: 0,



            c4: 0,
            c5: 0,

            comentario_c1: '',
            comentario_c2: '',
            comentario_c3: '',
            comentario_c4: '',
            comentario_c5: '',

            c3_pontos: 0,
            nota_zero: false,
            fuga_tema: false,
            texto_nao_articulado: false,
            identificacao_candidato: false,
            em_branco: false,
            texto_insuficiente: false,
            comentario: '',
            loading_save: false,
            msg_erro: '',
            show_warning_finish: false,
            show_error: false,
            show_success: false,
            annotations: [],
            processo: {},


            selects_in_text: [],
            selects_in_title: [],
            show_modal_aspect: false,
            new_anotation: null,
            annotation_edit: null,
            height_draw: 0
        }
        this.container = React.createRef();
        // this.videoRef=null;
    }


    componentDidMount() {
        this.get_dissertativas(this.props.match.params.id);

    }

    componentWillUnmount() {

    }

    componentDidUpdate(props) {
        if (props.resposta != this.state.resposta) {

        }
    }


    async get_dissertativas(id) {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/corretor/get_dissertativa/${this.props.match.params.processo_id}/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);

                }
                else {
                    let resposta = resp.resposta;
                    if(resposta && !resposta.resposta){
                        resposta={...resposta,resposta:{texto_resposta:''}};
                    }

                    this.setState({ domain: resp.host, loading_screen: false, resposta, processo: resp.processo }, () => {
                    });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);


            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);

            });
    }
    lines() {
        let lines = []
        // style={{ lineHeight: '38px' }}
        for (let i = 1; i <= 1000; i = i + 1) {
            lines.push(<p key={i} className="count_line" style={{ borderBottom: '1px solid lightgrey', lineHeight: '32px' }}
            >{i}</p>)
        }

        return lines;

    }

    async finalizar_correcao() {
        // console.log(window.scrollY)
        // window.scrollTo({ top: 0, left: 0, behavior: "instant" })

        // if (this.state.comentario_c1.trim().length == 0
        //     || this.state.comentario_c2.trim().length == 0
        //     || this.state.comentario_c3.trim().length == 0
        //     || this.state.comentario_c4.trim().length == 0
        //     || this.state.comentario_c5.trim().length == 0
        // ) {
        //     this.setState({ loading_save: false, msg_erro: 'Você deve comentar todos os critérios antes de finalizar a correção', show_error: true });
        //     return;
        // }


        console.log(document.querySelector('#redacao').scrollHeight);
        console.log(document.documentElement.offsetWidth);
        let img = null;

        let image1 = await html2canvas(document.querySelector('#redacao'), {
            scale: 0.9,
            quality: 0.1,
            useCORS: true
        });
        img = image1.toDataURL('image/jpg');
        console.log(img);




        // console.log({
        //     c1: this.state.c1,
        //     c2: this.state.c2,
        //     c3: this.state.c3,
        //     // c4: this.state.c4,
        //     // c5: this.state.c5,
        //     // nota_zero: this.state.nota_zero,
        //     // fuga_tema: this.state.fuga_tema,
        //     // texto_nao_articulado: this.state.texto_nao_articulado,
        //     // identificacao_candidato: this.state.identificacao_candidato,
        //     // em_branco: this.state.em_branco,
        //     // texto_insuficiente: this.state.texto_insuficiente,
        //     comentario: this.state.comentario,
        //     texto_id: this.state.resposta.id,
        //     candidato_id: this.state.resposta.user_id,

        //     criterios_notas_zeradas:this.state.criterios_notas_zeradas,
        //     image:img,
        //     annotations:this.state.annotations

        // });
        // this.setState({
        //                 loading_save: false,
        //             });
        //             return;

        fetch(`${URL}api/corretor/salvar_correcao_dissertativa`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                c1: this.state.c1,
                c2: this.state.c2,
                c3: this.state.c3,
                c4: this.state.c4,
                c5: this.state.c5,

                comentario_c1: this.state.comentario_c1,
                comentario_c2: this.state.comentario_c2,
                comentario_c3: this.state.comentario_c3,
                comentario_c4: this.state.comentario_c4,
                comentario_c5: this.state.comentario_c5,
                // nota_zero: this.state.nota_zero,
                // fuga_tema: this.state.fuga_tema,
                // texto_nao_articulado: this.state.texto_nao_articulado,
                // identificacao_candidato: this.state.identificacao_candidato,
                // em_branco: this.state.em_branco,
                // texto_insuficiente: this.state.texto_insuficiente,
                corretor_aluno_id:this.state.resposta.id,
                comentario: this.state.comentario,
                resposta_id: this.state.resposta.resposta.id?this.state.resposta.resposta.id:null,
                candidato_id: this.state.resposta.candidato_id,
                image: img,
                annotations: this.state.annotations

            })
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    this.setState({ loading_save: false, msg_erro: erro, show_error: true });

                }
                else {
                    let resposta = resp.resposta;

                    if(resposta && !resposta.resposta){
                        resposta={...resposta,resposta:{texto_resposta:''}};
                    }
                    // this.props.mudarProcesso(resp.processo);
                    this.setState({
                        loading_save: false, show_success: true, resposta,
                        c1: 0,
                        c2: 0,
                        // c3: 4,
                        c3: 0,
                        c4: 0,
                        c5: 0,
                        comentario_c1: '',
                        comentario_c2: '',
                        comentario_c3: '',
                        comentario_c4: '',
                        comentario_c5: '',

                        c3_pontos: 0,
                        nota_zero: false,
                        fuga_tema: false,
                        texto_nao_articulado: false,
                        identificacao_candidato: false,
                        em_branco: false,
                        texto_insuficiente: false,
                        comentario: '',
                        msg_erro: '',
                        annotations: [],


                    }, () => {
                        this.tamanho_annotation();

                    });
                    window.scrollTo(0, 0);

                }




            } catch (err) {
                console.log(err);

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });

            }

        })
            .catch((err) => {

                this.setState({ loading_save: false, msg_erro: 'Algo deu errado. Por favor, tente novamente', show_error: true });
                console.log(err);
                // this.props.mudarLoadingHome(false);
            });
    }

    is_overlapping(x1, x2, y1, y2) {
        if ((x1 < x2 && y1 < y2) || (x1 > y2 && y1 > y2)) {
            return false;
        }
        return true;
        //         let xmin = Math.min(x1,x2)
        //   , xmax = Math.max(x1,x2)
        //   , ymin = Math.min(y1,y2)
        //   , ymax = Math.max(y1,y2);

        //         for (let i = xmin; i < xmax; ++i)
        //             if (ymin <= i && i <= ymax)
        //                 return true;

        //         return false;

    }


    tamanho_annotation() {
        if (this.props.faculdade.criterio.numero_criterios == 5) {
            return;
        }
        let count = 0;
        let c3 = 0;
        for (let i = 0; i < this.state.annotations.length; i++) {
            count += this.state.annotations[i].data.aspectos.filter((item, id) => item.value == true).length;
        }
        if (count > 26) {
            c3 = 0;
        }
        else if (count <= 26 && count >= 20) {
            c3 = 1
        }
        else if (count <= 19 && count >= 13) {
            c3 = 2
        }
        else if (count <= 12 && count >= 7) {
            c3 = 3
        }
        else if (count <= 6) {
            c3 = 4;
        }

        this.setState({ c3_pontos: count, c3 });
        // return count;
    }

    render() {
        let index = 0;

        return (
            <div ref={this.container}>

                {this.state.loading_screen == false && <div className="row">
                    {this.state.redirect == true && <Redirect to={this.state.path} />}


                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <canvas id='myCanvas' hidden></canvas>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" >
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{ position: 'absolute', right: 0, width: '150px' }}>
                                            <Link onClick={() => {

                                            }} to={'/list/' + this.state.processo.id + '?tab=2'} className="btn btn-secondary btn-outline btn-circle m-r-5" style={{ width: '105px' }}><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>


                                        </div>
                                        <h4 style={{ color: 'black' }}>Questões dissertativas</h4>
                                        {/* <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line' }}>Sala {this.state.sala.numero}</p> */}
                                        <hr />
                                        {this.state.resposta == null && <div className="text-success" style={{ padding: '5px 5px 5px 5px', fontWeight: 'bold', fontSize: '1.4rem', textAlign: 'center' }}>
                                            Todas questões dissertativas já foram corrigidas
                                        </div>}
                                        {this.state.resposta != null && <div >
                                            <p style={{fontSize:16,fontWeight:'bold',color:'black'}}>Questão Número {this.state.resposta?.questao_order.order+1})</p>
                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.state.resposta?.questao?.name }}>

                                            </div>
                                            <br/>
                                            <p style={{fontSize:16,fontWeight:'bold',color:'black'}}>Resposta: <p style={{fontWeight:'normal'}}>{(this.state.resposta.resposta.texto_resposta==null || this.state.resposta.resposta.texto_resposta=='')?'Em branco':''}</p></p>

                                            <div className='hidden_scrollbar d-block d-lg-flex' style={{ justifyContent: 'center', alignItems: 'center', overflowX: 'scroll' }}>

                                                <div id="redacao" style={{ width: this.state.resposta.file == null ? 750 : '1050px', height: this.state.resposta.file == null ? 'auto' : '1550px' }}>
                                                    <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Candidato #{(this.state.resposta.candidato.numero_inscricao == null) ? (this.state.resposta.candidato_id) : (this.state.resposta.candidato.numero_inscricao)}
                                                    </p>
                                                    {/* <button className="btn btn-info" onMouseDown={this.getSelText.bind(this)}>Selecionar</button> */}
                                                    <div style={{}}>
                                                        <br />
                                                        <div className={(this.state.resposta.file != null ? '' : 'row  ') + " container_scroll justify-content-center"}
                                                            style={{ paddingBottom: '1.25rem', display: 'block', paddingLeft: '2rem', overflow: 'hidden', flexWrap: 'inherit', position: 'relative' }}>

                                                            <div style={{ width: 1010, paddingLeft: '2rem', zIndex: 3, position: 'relative' }}>


                                                                {<div

                                                                    ref={this.textoRef} id='texto_questao' name="texto" autoComplete="off" className="lines" style={{ paddingTop: 30, zIndex: 1, width: '678px', whiteSpace: 'pre-wrap', height: 'auto', paddingBottom: 50, minHeight: 300 }} dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            //"   "
                                                                            (this.state.resposta.resposta.texto_resposta == null ? '' : this.state.resposta.resposta.texto_resposta)
                                                                    }} maxLength={10000} role="textbox">
                                                                </div>}
                                                                {/* <div
                                                      
                                                      name="texto1" autoComplete="off" className="lines" style={{zIndex:2, position:'absolute',top:0, width: '678px', whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: "\t" + (this.state.resposta.texto == null ? '' : this.state.resposta.texto) }} maxLength={3000} rows={30} role="textbox">
                                                 </div> */}
                                                                <DrawAnnotations
                                                                    height={5000}
                                                                    width={1010}
                                                                    dissertativa
                                                                    redacao={this.state.resposta}
                                                                    annotation_edit={this.state.annotation_edit}
                                                                    showEditAnnotation={(e) => {
                                                                        let annotation_edit = { ...this.state.annotations[e] };
                                                                        this.setState({ show_modal_aspect: true, annotation_edit: annotation_edit });
                                                                    }}
                                                                    annotations={this.state.annotations} MouseEnter={(id, value) => {
                                                                        let annotations = [...this.state.annotations];
                                                                        annotations[id].mouse_inside = value;
                                                                        this.setState({ annotations }, () => {
                                                                            this.tamanho_annotation();
                                                                        });

                                                                    }} new_anotation={this.state.new_anotation} newAnnotation={(e) => {
                                                                        //  this.new_anotation=e;

                                                                        this.setState({ show_modal_aspect: true, new_anotation: e });
                                                                    }} />
                                                                <ModalErrors
                                                                    removeAnotation={(annotation) => {
                                                                        let annotations = this.state.annotations.filter((item, id) => item.id != annotation.id);
                                                                        this.setState({ annotations }, () => {
                                                                            this.tamanho_annotation();
                                                                        });
                                                                    }}
                                                                    annotation_edit={this.state.annotation_edit} show_modal_aspect={this.state.show_modal_aspect} setState={(e) => {
                                                                        this.setState({ ...e, annotation_edit: null, new_anotation: null });
                                                                    }} onSave={(data) => {
                                                                        let annotations = [...this.state.annotations];
                                                                        let label = '';
                                                                        data.aspectos.map((item, id) => {
                                                                            if (item.value == true) {
                                                                                console.log(item.sigla);
                                                                                if (id == 0) {
                                                                                    label = item.sigla;
                                                                                }
                                                                                else {
                                                                                    label += ', ' + item.sigla;
                                                                                }
                                                                            }
                                                                        });
                                                                        if (data.annotation_edit == null) {
                                                                            let anot = this.state.new_anotation;
                                                                            anot.data = data;
                                                                            anot.data.label = label;
                                                                            annotations.push(anot);
                                                                        }
                                                                        else {
                                                                            annotations = annotations.filter((item, id) => {
                                                                                if (item.id == data.annotation_edit.id) {
                                                                                    item = data.annotation_edit;
                                                                                    item.data.aspectos = data.aspectos;
                                                                                    item.data.comentario = data.comentario;
                                                                                    item.data.label = data.label;

                                                                                    return item;
                                                                                }
                                                                                else {
                                                                                    return item;
                                                                                }
                                                                            });
                                                                        }
                                                                        this.new_anotation = null;
                                                                        this.setState({ annotations, new_anotation: null, annotation_edit: null }, () => {
                                                                            this.tamanho_annotation();
                                                                        });
                                                                    }} />
                                                            </div>
                                                            {<div style={{
                                                                height: 'auto', overflow: 'hidden', position: 'absolute',
                                                                width: '710px',
                                                                zIndex: 0, top: '28px'
                                                            }} ref={this.lineCount}
                                                            >
                                                                {this.lines()}

                                                            </div>}


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div style={{ marginTop: 30 }}>

                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Marcações</p>
                                                </div>
                                                <div className="table-rep-plugin" style={{ marginTop: '2rem' }}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>Número</th>


                                                                    <th>Aspectos</th>
                                                                    <th>Comentário</th>
                                                                    {/* <th>Ações</th> */}

                                                                    {/* <th>NOTA OBTIDA</th> */}

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.annotations.map((item, id) => <tr key={id} style={{ textAlign: 'center' }}>
                                                                    <th>{id + 1})</th>
                                                                    <th style={{ whiteSpace: 'pre-line' }}>{item.data.aspectos.map((item2, id2) => {
                                                                        if (item2.value == true) {
                                                                            return item2.name + "\n";
                                                                        }
                                                                    })}</th>
                                                                    <th>{item.data.comentario?.trim()?.length == 0 ? '-' : item.data.comentario}</th>
                                                                    {/* <td>
                                                                        <button onClick={() => {
                                                                            let annotation_edit = { ...item };
                                                                            this.setState({ show_modal_aspect: true, annotation_edit: annotation_edit });
                                                                        }} className="btn btn-info btn-outline btn-circle m-r-5"
                                                                            data-toggle="tooltip"
                                                                        ><i className="ti-pencil-alt"></i></button>
                                                                        <button onClick={() => {
                                                                            let annotations = this.state.annotations.filter((item2, id2) => item2.id != item.id);
                                                                            this.setState({ annotations }, () => {
                                                                                this.tamanho_annotation();
                                                                            });
                                                                        }} className="btn btn-danger btn-outline btn-circle m-r-5"
                                                                            data-toggle="tooltip"
                                                                        ><i className="ti-trash"></i></button>
                                                                    </td> */}

                                                                </tr>)}
                                                            </tbody>
                                                        </table>
                                                        {this.state.annotations.length == 0 && <div className="row w-100">
                                                            <div className="col-12">
                                                                <p style={{ textAlign: 'center' }}>Nenhuma marcação registrada</p>

                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <br />
                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Critérios</p>
                                                </div>

                                                {<div className="table-rep-plugin" style={{ marginTop: '2rem' }}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>
                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th>NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C1</th>
                                                                    <th>Atendimento ao tema proposto na questão.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                        <select value={this.state.c1} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c1: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>


                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C1<b style={{ color: 'red' }}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        <textarea style={{ height: 45 }} className="form-control" value={this.state.comentario_c1} onChange={(e) => {
                                                                            this.setState({ comentario_c1: e.target.value });
                                                                        }}></textarea>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C2</th>
                                                                    <th>Clareza de argumentação/senso crítico.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                        <select value={this.state.c2} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c2: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>


                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C2<b style={{ color: 'red' }}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        <textarea style={{ height: 45 }} className="form-control" value={this.state.comentario_c2} onChange={(e) => {
                                                                            this.setState({ comentario_c2: e.target.value });
                                                                        }}></textarea>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C3</th>
                                                                    <th>Seletividade de informação.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                        <select value={this.state.c3} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c3: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>

                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C3<b style={{ color: 'red' }}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        <textarea style={{ height: 45 }} className="form-control" value={this.state.comentario_c3} onChange={(e) => {
                                                                            this.setState({ comentario_c3: e.target.value });
                                                                        }}></textarea>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C4</th>
                                                                    <th>Criatividade/Originalidade.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                        <select value={this.state.c4} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c4: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>


                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C4<b style={{ color: 'red' }}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        <textarea style={{ height: 45 }} className="form-control" value={this.state.comentario_c4} onChange={(e) => {
                                                                            this.setState({ comentario_c4: e.target.value });
                                                                        }}></textarea>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C5</th>
                                                                    <th>Utilização adequada da Língua Portuguesa.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                        <select value={this.state.c5} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c5: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>


                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C5<b style={{ color: 'red' }}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        <textarea style={{ height: 45 }} className="form-control" value={this.state.comentario_c5} onChange={(e) => {
                                                                            this.setState({ comentario_c5: e.target.value });
                                                                        }}></textarea>
                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>


                                                    </div>
                                                </div>}


                                                <div className="row" style={{ marginTop: '2rem' }}>
                                                    <div className="col-12" style={{ marginBottom: '2rem' }}>
                                                        <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>Nota final: <span style={{ fontWeight: 'normal' }}>{(parseFloat(this.state.c1) + parseFloat(this.state.c2) + parseFloat(this.state.c3) + parseFloat(this.state.c4) + parseFloat(this.state.c5))}</span></p>
                                                        <div >

                                                        </div>

                                                    </div>

                                                </div>



                                                <div className="row" >
                                                    <div className="col-12" style={{ marginBottom: '2rem' }}>
                                                        <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>Comentário Geral</p>
                                                        <textarea style={{ height: 100 }} className="form-control" value={this.state.comentario} onChange={(e) => {
                                                            this.setState({ comentario: e.target.value });
                                                        }}></textarea>
                                                    </div>

                                                </div>

                                                <div className="row">
                                                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <span id="msg" style={{ color: 'red', fontSize: '25px', textAlign: 'center' }}>{this.state.msgErro}</span>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-end align-items-center">
                                                        {this.state.loading_save == true && <div className="spinner-border text-primary" role="status">
                                                        </div>}
                                                        {this.state.loading_save == false && <button onClick={() => {
                                                            // if(this.state.c1=='' || this.state.c2=='' || this.state.c3==''){
                                                            //     this.setState({msg_erro: 'Preencha todos os critérios de nota para finalizar a correção', show_error: true});
                                                            //     return;
                                                            // }
                                                            // this.state.criterios_notas_zeradas.map((item,id)=>{
                                                            //     if(item.value==)
                                                            // })
                                                            this.setState({ show_warning_finish: true });

                                                        }} type="button" className="btn btn-success btn-lg waves-effect waves-light finish_redacao"
                                                        >Finalizar Correção e Avançar</button>}
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
                <SweetAlert
                    warning
                    title={"Você tem certeza que deseja finalizar esta correção?"}
                    onConfirm={() => {
                        this.setState({ loading_save: true, show_warning_finish: false });
                        this.finalizar_correcao();
                    }}
                    onCancel={() => this.setState({ show_warning_finish: false })}
                    show={this.state.show_warning_finish}
                    confirmBtnText='Finalizar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao finalizar você não poderá realizar mais nenhuma alteração nesta correção
                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {this.state.msg_erro}

                </SweetAlert>
                <SweetAlert
                    success
                    title={"Correção Finalizada!"}
                    onConfirm={() => this.setState({ show_success: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    A correção foi finalizada com sucesso. A próxima questão já está nesta página para correção.
                </SweetAlert>
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout, mudarProcesso, mudarSocket })(CorrecaoDissertativa);
