import React from "react";

import {Button, CircularProgress, Tooltip, Zoom} from "@material-ui/core";
import { useHistory } from "react-router-dom";

const colors = {
    primary: "rgb(98, 110, 212)",
    secondary: "#6c757d",
    confirm: "#198754",
    warning: "#ffc107",
    danger: "#dc3545",
    info:'#007bff'
}

const DefaultButton = ({
    loading = false, 
    disabled = false,
    text = "", 
    loadingtext = "",
    bg = Object.keys(colors)[0], 
    color = "white", 
    variant = "contained", 
    type = "button",
    icon = <></>,
    to,
    routeState = {},
    title = "",
    width = "auto",
    height = "auto",
    padding = "auto",
    style = {},
    onClick = (e) => void 0,
    search='',
    ...other
}) => {

    const history = useHistory();

    return (
        <Tooltip
            title={title}
            TransitionComponent={Zoom}
            {...other}
        >
            <Button
                variant={variant}
                disabled={loading || disabled}
                style={Object.assign({
                    backgroundColor: (bg in colors ? colors[`${bg}`] : bg),
                    color: (color in colors ? colors[`${color}`] : color),
                    position: "relative",
                    textDecoration: "none",
                    minWidth: "auto",
                    width: width,
                    height: height,
                    padding: padding
                }, style)}
                type={type}
                onClick={(e) => {
                    onClick(e);
                    to && history.push({
                        pathname: to,
                        state: routeState,
                        search: search
                    });
                }}
                className="flex jcc align-center"
            >
                <CircularProgress
                    className={loading ? "" : "hide"}
                    style={{
                        width: "15px",
                        height: "15px",
                        color: color,
                        display:loading?'block':'none'
                    }}
                />
                {icon}
                {loadingtext != "" && loading ? loadingtext : text}
            </Button>
        </Tooltip>
    );
}

export default DefaultButton;