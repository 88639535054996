
import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';

import moment from 'moment-timezone';
import Pagination from '@material-ui/lab/Pagination';



class Dissertativas extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: false,
            show_video: false,
            show_solicitar: true,
            show_aceitar: false,
            stream: null,
            redirect: false,
            path: '/sala',
            correcoes: [],
            pagination: { current_page: 1, last_page: 1 },
            processo: {},
            loading_content:false
        }
        this.videoRef = React.createRef();

        // this.videoRef=null;
    }


    async get_correcoes(id,page=1,more=false) {
        if(more==false){
            this.setState({ loading_screen: true });
        }
        else{
            this.setState({ loading_content: true });
        }
        fetch(`${URL}api/corretor/get_dissertativas/${id}?page=${page}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);
                    setTimeout(() => {
                        this.get_correcoes(id);
                    }, 5000);
                }
                else {
                    this.setState({loading_content:false, loading_screen: false, processo: resp.processo, correcoes: resp.respostas, pagination: resp.pagination });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_correcoes(id);
                }, 5000);

            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);
                setTimeout(() => {
                    this.get_correcoes(id);
                }, 5000);
            });
    }


    componentDidMount() {
        this.get_correcoes(this.props.processo_id);

    }

    componentWillUnmount() {
        clearTimeout(this.logs_timeout);
    }


    render() {
        return (
            <div>
                {this.state.loading_screen == false && <div>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <table id="tech-companies-1" className="table table-striped">
                                <thead>
                                    <tr>

                                        <th>Número de Inscrição</th>
                                        <th>Número da Questão</th>

                                        {/* <th data-priority="1">Descrição</th> */}

                                        <th data-priority="3">Ações</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.loading_content == false && this.state.correcoes.map((item, id) => (

                                        <tr key={id}>

                                            <th>#{item.candidato.numero_inscricao}</th>
                                            <th>{item.questao_order.order+1})</th>

                                            {/* <td ><div dangerouslySetInnerHTML={{__html:item.descricao}}></div></td> */}
                                            {/* {this.props.faculdade!=null && this.props.faculdade.criterio.numero_criterios==5 && <td>{item.criterios.filter((item2,id2)=>item2.value==true).length>0?0:(parseFloat(item.c1)+parseFloat(item.c2)+parseFloat(item.c3) +parseFloat(item.c4) +parseFloat(item.c5))}</td>} */}

                                            <td>
                                                <Link to={'/dissertativas/'+this.state.processo.id+'/' + item.id}><span className="btn btn-success btn-outline btn-circle m-r-5"
                                                    data-toggle="tooltip"
                                                ><i className="mdi mdi-eye"></i></span></Link>
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    {this.state.correcoes.length == 0 && this.state.loading_content == false && <div className="row w-100">
                        <div className="col-12">
                            <p style={{ textAlign: 'center' }}>Nenhuma questão dissertativa encontrada</p>

                        </div>
                    </div>}

                    {this.state.loading_content && <div className="row w-100">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <div className="spinner-border text-primary" role="status"  >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>}
                    {/* <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <ReactPaginate
                                previousLabel={'Anterior'}
                                nextLabel={'Próxima'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={this.state.total_pages}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={2}
                                onPageChange={this.handlePageClick.bind(this)}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                initialPage={this.state.page - 1}
                            />

                            
                        </div>
                    </div> */}
                    <Pagination
                        className={(this.state.pagination.last_page == 1 ? "hide" : "")}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                        count={this.state.pagination.last_page}
                        value={this.state.pagination.current_page}
                        onChange={(_, value) => {
                            console.log(_, value);
                            let page = { ...this.state.pagination };
                            page.current_page = value;
                            this.get_correcoes(this.props.processo_id, value, true)
                        }}
                    />




                </div>}

                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
            </div>
        )
    }
}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout })(Dissertativas);