import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import moment from 'moment-timezone';
import DrawAnnotations from './DrawAnotations';
import ModalErrors from './ModalErrors';
import html2canvas from 'html2canvas';

class CorrecaoDissertativaCorrigida extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            sala: {},
            resposta: {},
            domain: '',
            height: 0,
            width: 100,
            c1: 0,
            c2: 0,

            // c3: 4,
            c3: 0,



            c4: 0,
            c5: 0,

            comentario_c1:'',
            comentario_c2:'',
            comentario_c3:'',
            comentario_c4:'',
            comentario_c5:'',

            c3_pontos: 0,
            nota_zero: false,
            fuga_tema: false,
            texto_nao_articulado: false,
            identificacao_candidato: false,
            em_branco: false,
            texto_insuficiente: false,
            comentario: '',
            loading_save: false,
            msg_erro: '',
            show_warning_finish: false,
            show_error: false,
            show_success: false,
            annotations: [],
            processo: {},


            selects_in_text: [],
            selects_in_title: [],
            show_modal_aspect: false,
            new_anotation: null,
            annotation_edit: null,
            height_draw: 0
        }
        this.container = React.createRef();
        // this.videoRef=null;
    }


    componentDidMount() {
        this.get_dissertativas(this.props.match.params.id);

    }

    componentWillUnmount() {

    }

    componentDidUpdate(props) {
        if (props.resposta != this.state.resposta) {

        }
    }


    async get_dissertativas(id) {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/corretor/get_dissertativa_corrigida/${this.props.match.params.processo_id}/${id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);

                }
                else {
                    let resposta = resp.resposta;
                    if(resposta && !resposta.resposta){
                        resposta={...resposta,resposta:{texto_resposta:''}};
                    }
                    let annotations=[]
                    annotations = resp.resposta.correcoes_dissertativas[0].annotations.map((item)=>{
                        item.aspectos=item.aspects;
                        return item;
                    })

                    this.setState({ domain: resp.host, loading_screen: false, resposta, processo: resp.processo,
                    annotations:annotations,
                    c1:resp.resposta.correcoes_dissertativas[0].c1,
                    c2:resp.resposta.correcoes_dissertativas[0].c2,
                    c3:resp.resposta.correcoes_dissertativas[0].c3,
                    c4:resp.resposta.correcoes_dissertativas[0].c4,
                    c5:resp.resposta.correcoes_dissertativas[0].c5,

                    comentario_c1:resp.resposta.correcoes_dissertativas[0].justify_c1 ?? '-',
                    comentario_c2:resp.resposta.correcoes_dissertativas[0].justify_c2 ?? '-',
                    comentario_c3:resp.resposta.correcoes_dissertativas[0].justify_c3 ?? '-',
                    comentario_c4:resp.resposta.correcoes_dissertativas[0].justify_c4 ?? '-',
                    comentario_c5:resp.resposta.correcoes_dissertativas[0].justify_c5 ?? '-',
                    comentario:resp.resposta.correcoes_dissertativas[0].geral_comment ?? '-'

                    
                    }, () => {
                    });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);


            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);

            });
    }
    lines() {
        let lines = []
        // style={{ lineHeight: '38px' }}
        for (let i = 1; i <= 1000; i = i + 1) {
            lines.push(<p key={i} className="count_line" style={{ borderBottom: '1px solid lightgrey', lineHeight: '32px' }}
            >{i}</p>)
        }

        return lines;

    }

   

    is_overlapping(x1, x2, y1, y2) {
        if ((x1 < x2 && y1 < y2) || (x1 > y2 && y1 > y2)) {
            return false;
        }
        return true;
        //         let xmin = Math.min(x1,x2)
        //   , xmax = Math.max(x1,x2)
        //   , ymin = Math.min(y1,y2)
        //   , ymax = Math.max(y1,y2);

        //         for (let i = xmin; i < xmax; ++i)
        //             if (ymin <= i && i <= ymax)
        //                 return true;

        //         return false;

    }


   

    render() {
        let index = 0;

        return (
            <div ref={this.container}>

                {this.state.loading_screen == false && <div className="row">
                    {this.state.redirect == true && <Redirect to={this.state.path} />}


                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <canvas id='myCanvas' hidden></canvas>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" >
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{  position: 'absolute', right: 0, width: '150px' }}>
                                            <Link onClick={() => {

                                            }} to={'/list/' + this.state.processo.id+'?tab=3'} className="btn btn-secondary btn-outline btn-circle m-r-5" style={{ width: '105px' }}><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>


                                        </div>
                                        <h4 style={{ color: 'black' }}>Questão dissertativa</h4>
                                        {/* <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line' }}>Sala {this.state.sala.numero}</p> */}
                                        <hr />
                                        {this.state.resposta != null && <div >
                                            <p style={{fontSize:16,fontWeight:'bold',color:'black'}}>Questão Número {this.state.resposta?.questao_order.order+1})</p>
                                            <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{ __html: this.state.resposta?.questao?.name }}>

                                            </div>
                                            <br/>
                                            <p style={{fontSize:16,fontWeight:'bold',color:'black'}}>Resposta:</p>
                                            <div className='hidden_scrollbar d-block d-lg-flex' style={{ justifyContent: 'center', alignItems: 'center', overflowX: 'scroll' }}>

                                                <div id="redacao" style={{ width: this.state.resposta.file == null ? 750 : '1050px', height: this.state.resposta.file == null ? 'auto' : '1550px' }}>
                                                    <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Candidato #{(this.state.resposta.candidato.numero_inscricao == null) ? (this.state.resposta.candidato_id) : (this.state.resposta.candidato.numero_inscricao)}
                                                    </p>
                                                    {/* <button className="btn btn-info" onMouseDown={this.getSelText.bind(this)}>Selecionar</button> */}
                                                    <div style={{}}>
                                                        <br />
                                                        <div className={(this.state.resposta.file != null ? '' : 'row  ') + " container_scroll justify-content-center"}
                                                            style={{ paddingBottom: '1.25rem', display: 'block', paddingLeft: '2rem', overflow: 'hidden', flexWrap: 'inherit', position: 'relative' }}>

                                                            <div style={{ width: 1010, paddingLeft: '2rem', zIndex: 3, position: 'relative' }}>


                                                                {<div

                                                                    ref={this.textoRef} id='texto_questao' name="texto" autoComplete="off" className="lines" style={{ paddingTop: 30, zIndex: 1, width: '678px', whiteSpace: 'pre-wrap', height: 'auto', paddingBottom: 50, minHeight: 300 }} dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            //"   "
                                                                            (this.state.resposta.resposta.texto_resposta == null ? '' : this.state.resposta.resposta.texto_resposta)
                                                                    }} maxLength={10000} role="textbox">
                                                                </div>}
                                                                {/* <div
                                                      
                                                      name="texto1" autoComplete="off" className="lines" style={{zIndex:2, position:'absolute',top:0, width: '678px', whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: "\t" + (this.state.resposta.texto == null ? '' : this.state.resposta.texto) }} maxLength={3000} rows={30} role="textbox">
                                                 </div> */}
                                                                <DrawAnnotations
                                                                    height={5000}
                                                                    width={1010}
                                                                    dissertativa
                                                                    redacao={this.state.resposta}
                                                                    annotation_edit={this.state.annotation_edit}
                                                                    showEditAnnotation={(e) => {
                                                                       
                                                                    }}
                                                                    annotations={this.state.annotations} MouseEnter={(id, value) => {
                                                                       

                                                                    }} new_anotation={this.state.new_anotation} newAnnotation={(e) => {
                                                                        //  this.new_anotation=e;
                                                                    }} />
                                                                <ModalErrors
                                                                    removeAnotation={(annotation) => {
                                                                        
                                                                    }}
                                                                    annotation_edit={this.state.annotation_edit} show_modal_aspect={this.state.show_modal_aspect} setState={(e) => {

                                                                    }} onSave={(data) => {
                                                                        
                                                                    }} />
                                                            </div>
                                                            {<div style={{
                                                                height: 'auto', overflow: 'hidden', position: 'absolute',
                                                                width: '710px',
                                                                zIndex: 0, top: '28px'
                                                            }} ref={this.lineCount}
                                                            >
                                                                {this.lines()}

                                                            </div>}


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div style={{ marginTop: 30 }}>

                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Marcações</p>
                                                </div>
                                                <div className="table-rep-plugin" style={{ marginTop: '2rem' }}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>Número</th>


                                                                    <th>Aspectos</th>
                                                                    <th>Comentário</th>

                                                                    {/* <th>NOTA OBTIDA</th> */}

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.annotations.map((item, id) => <tr key={id} style={{ textAlign: 'center' }}>
                                                                    <th>{id + 1})</th>
                                                                    <th style={{ whiteSpace: 'pre-line' }}>{item.aspectos.map((item2, id2) => {
                                                                        if (item2.value == true) {
                                                                            return item2.name + "\n";
                                                                        }
                                                                    })}</th>
                                                                    <th>{item.comentario?.trim()?.length == 0 ? '-' : item.comentario}</th>
                                                                    

                                                                </tr>)}
                                                            </tbody>
                                                        </table>
                                                        {this.state.annotations.length == 0 && <div className="row w-100">
                                                            <div className="col-12">
                                                                <p style={{ textAlign: 'center' }}>Nenhuma marcação registrada</p>

                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <br />
                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Critérios</p>
                                                </div>

                                                {<div className="table-rep-plugin" style={{ marginTop: '2rem' }}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>
                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th>NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C1</th>
                                                                    <th>Atendimento ao tema proposto na questão.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                       {this.state.c1}
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C1<b style={{color:'red'}}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                        <p style={{marginBottom:0,whiteSpace:'pre-line'}}>{this.state.comentario_c1}</p>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C2</th>
                                                                    <th>Clareza de argumentação/senso crítico.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                    {this.state.c2}
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C2<b style={{color:'red'}}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                    <p style={{marginBottom:0,whiteSpace:'pre-line'}}>{this.state.comentario_c2}</p>

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C3</th>
                                                                    <th>Seletividade de informação.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                    {this.state.c3}
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C3<b style={{color:'red'}}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                    <p style={{marginBottom:0,whiteSpace:'pre-line'}}>{this.state.comentario_c3}</p>

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C4</th>
                                                                    <th>Criatividade/Originalidade.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                    {this.state.c4}
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C4<b style={{color:'red'}}></b>: 
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                    <p style={{marginBottom:0,whiteSpace:'pre-line'}}>{this.state.comentario_c4}</p>

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C5</th>
                                                                    <th>Utilização adequada da Língua Portuguesa.</th>
                                                                    <th>3.0</th>
                                                                    <th>
                                                                    {this.state.c5}
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Comentário C5<b style={{color:'red'}}></b>:
                                                                    </th>
                                                                    <th colSpan={3}>
                                                                    <p style={{marginBottom:0,whiteSpace:'pre-line'}}>{this.state.comentario_c5}</p>

                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>


                                                    </div>
                                                </div>}


                                                <div className="row" style={{ marginTop: '2rem' }}>
                                                    <div className="col-12" style={{ marginBottom: '2rem' }}>
                                                        <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>Nota final: <span style={{ fontWeight: 'normal' }}>{ (parseFloat(this.state.c1) + parseFloat(this.state.c2) + parseFloat(this.state.c3) + parseFloat(this.state.c4) + parseFloat(this.state.c5))}</span></p>
                                                        <div >

                                                        </div>

                                                    </div>

                                                </div>



                                                <div className="row" >
                                                    <div className="col-12" style={{ marginBottom: '2rem' }}>
                                                        <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>Comentário Geral</p>
                                                        <p style={{marginBottom:0,whiteSpace:'pre-line'}}>{this.state.comentario}</p>
                                                    </div>

                                                </div>

                                                
                                            </div>

                                        </div>}


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
               
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {this.state.msg_erro}

                </SweetAlert>
               
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout, mudarProcesso, mudarSocket })(CorrecaoDissertativaCorrigida);
